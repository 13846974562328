@mixin num_inline {
  float: none;
  margin-left: 0;
  width: auto;
  padding-left: 0;
  padding-right: 0;
  & + span {
    float: none;
    width: auto;
  }
}
.svc-logic-question-value, .sd-element--with-frame:not(.sd-element--collapsed) {
  border-radius: calcSize(0.5);
  box-sizing: border-box;
  padding-left: var(--sd-base-padding);
  padding-right: var(--sd-base-padding);
  padding-bottom: var(--sd-base-padding);
  background: $background;
  box-shadow: 0px 1px 2px $shadow-inner;
  & > .sd-question__erbox--outside-question {
    margin-left: calc(-1 * var(--sd-base-padding));
    margin-right: calc(-1 * var(--sd-base-padding));
    width: calc(100% + 2 * var(--sd-base-padding));
  }
  &.sd-question--paneldynamic {
    padding-bottom: 0;
    & > .sd-question__erbox--below-question {
      bottom: 0;
      margin-top: 0;
    }
  }
}
.svc-logic-question-value,
.sd-element:not(.sd-element--collapsed) {
  padding-top: var(--sd-base-vertical-padding);
}
.sd-table__cell--detail-panel {
  .sd-element:not(.sd-element--collapsed) {
    padding-top: calcSize(0.5);
  }
}
.sd-element.sd-element--complex {
  padding-top: 0;
}
.sd-element--expanded, .sd-element--collapsed {
  & > .sd-element__header {
    cursor: pointer;;
  } 
}
.sd-element--collapsed {
  & > .sd-element__header {
    padding: calcSize(2) var(--sd-base-padding);
    box-sizing: border-box;
    background-color: $background;
  }
  &.sd-element--with-frame > .sd-element__header {
    box-shadow: 0px 1px 2px $shadow-inner;
    border-radius: calcSize(0.5);
  }
  & > .sd-element__header:focus-within,
  & > .sd-element__header:hover {
    background-color: $background-dim-light;
  }
}
.sd-element__title {
  outline: none;
}
.sd-element--collapsed:not(.sd-element--with-frame) > .sd-element__header {
  margin-left: calc(-1 * var(--sd-base-padding));
  width: calc(100% + 2 * var(--sd-base-padding));
}

.sd-element--complex:not(.sd-element--collapsed) > .sd-element__header--location-top {
  padding-top: var(--sd-base-vertical-padding);
  padding-bottom: var(--sd-base-vertical-padding);
  &:after {
    content: " ";
    display: block;
    position: relative;
    bottom: calc(-1 * var(--sd-base-vertical-padding));
    height: 1px;
    background: $border-light;
  }
}
.sd-element--complex.sd-element--with-frame > .sd-element__header--location-top:after {
  content: " ";
  left: calc(-1 * var(--sd-base-padding));
  width: calc(100% + 2 * var(--sd-base-padding));
}

.sd-element--nested {
  &.sd-element--complex {
    & > .sd-element__header--location-top {
      padding-bottom: calc(0.5 * var(--sd-base-vertical-padding));
      &:after {
        bottom: calc(-0.5 * var(--sd-base-vertical-padding));
      }
    }
  }
  &.sd-panel,
  &.sd-question--paneldynamic {
    border: 1px solid $border-light;
    box-sizing: border-box;
    padding-left: var(--sd-base-padding);
    padding-right: var(--sd-base-padding);
    margin-top: var(--sd-base-vertical-padding);
    & > .sd-element__header--location-top {
      padding-top: calc(0.5 * var(--sd-base-vertical-padding));
      padding-bottom: calc(0.5 * var(--sd-base-vertical-padding));
      &:after {
        left: calc(-1 * var(--sd-base-padding));
        width: calc(100% + 2 * var(--sd-base-padding));
        bottom: calc(-0.5 * var(--sd-base-vertical-padding));
      }
    }
  }
  &.sd-panel:not(.sd-element--collapsed) {
    padding-bottom: var(--sd-base-padding);
  }
}
.svc-question__content .sd-element {
  padding-top: 0;
}
.svc-question__content .sd-element--complex > .sd-element__header--location-top {
  padding-bottom: calc(0.5 * var(--sd-base-vertical-padding));
  padding-top: 0;
  &:after {
    display: none;
  }
}
.sd-element--invisible {
  opacity: 0.35;
}
.sd-element__title {
  font-size: 0;
  line-height: 0;
  position: static;
  font-weight: 600;
  margin: 0;
  .sd-element__num {
    font-size: calcSize(1.5);
    line-height: calcSize(2);
  }
  span {
    font-size: calcSize(2);
    line-height: calcSize(3);
  }
  .sv-title-actions__title {
    font-size: 0;
    line-height: 0;
  }

  .svc-string-editor {
    display: inline-block;
    max-width: 100%;
  }
  .sv-string-editor {
    max-width: 100%;
    white-space: normal;
  }

  .sv-title-actions__title {
    white-space: nowrap;
  }
  .sv-string-viewer {
    white-space: normal;
  }
}
.sd-element__num {
  float: left;
  padding: calcSize(0.625) calcSize(1) calcSize(0.375);
  width: calcSize(4);
  font-size: calcSize(1.5);
  line-height: calcSize(2);
  color: $foreground-light;
  margin-inline-start: calcSize(-4); // margin-left
  text-align: right;
  box-sizing: border-box;
  white-space: nowrap;
  flex-shrink: 0;
  & + span {
    float: left;
    width: 0;
  }
}
div:not(:only-of-type) > .sd-element > .sd-question__header,
.sd-element__title--expandable {
  .sd-action-bar {
    margin-right: 0;
  }
  .sd-element__num {
    @include num_inline();
  }
}
.sd-element__title--expandable.sd-element__title--expandable {
  position: relative;
  display: block;
}
.sd-element__title--expandable:before {
  content: "";
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 14L5 13L10 8L5 3L6 2L12 8L6 14Z' fill='%23909090'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center center;
  height: calcSize(2);
  width: calcSize(2);
  position: absolute;
  left: calcSize(-3);
  top: calcSize(0.5);
}
.sd-element__title--expandable.sd-element__title--expanded:before {
  transform: rotate(90deg);
}
